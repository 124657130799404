import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ barHeight, progressPercentage }) => {
  return(
    <div className="progress" style={{ height: `${barHeight}px` }}>
      <div
        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
        role="progressbar"
        style={{ width: `${progressPercentage}%` }}
        aria-valuenow={`${progressPercentage}`}
        aria-valuemin="0"
        aria-valuemax="100">{progressPercentage}%</div>
    </div>
  )
}

export default ProgressBar;
