import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "../Common/ProgressBar/ProgressBar.jsx"; // Adjust the path as necessary
import Zmodem from "zmodem.js";
import "./FileTransfer.scss";
import logo from '../../img/avionica.png';

const FileTransfer = () => {
  const [selectedSerialPort, setSelectedSerialPort] = useState(null);
  const [zmodemTransferProgress, setZmodemTransferProgress] = useState(0); // Ensure this is a number
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isTransferring, setIsTransferring] = useState(false);
  const [zmodemTransferComplete, setZmodemTransferComplete] = useState(false);
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deviceNotFound, setDeviceNotFound] = useState(false);
  let readerRef = useRef(null);
  let writerRef = useRef(null);
  let wsRef = useRef(null);
//
  useEffect(() => {
    const ws = new WebSocket("wss://fdl-service.azurewebsites.net");
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "uploadProgress") {
        setUploadProgress(data.percentComplete);
        console.log("data.complete", data.percentComplete);
        setZmodemTransferComplete(true);
        if (data.percentComplete === "100.00") {
          cleanupAfterTransfer();
          fetchFiles();
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => ws.close();
  }, []);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = () => {
    fetch("https://fdl-service.azurewebsites.net/downloads")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        setFiles(data);
      })
      .catch(error => console.error("Error fetching files:", error));
  };

  const connectSerialPort = async () => {
    const PICO_VENDOR_ID = 0x2E8A; // Replace this with the actual Pico Vendor ID
    const PICO_PRODUCT_ID = 0x000A; // Replace this with the actual Pico Product ID
  
    if ("serial" in navigator) {
      try {
        console.log("Requesting access to the serial port...");
  
        // Get all authorized ports
        const ports = await navigator.serial.getPorts();
        let picoPort = ports.find(port => {
          const info = port.getInfo();
          return info.usbVendorId === PICO_VENDOR_ID && info.usbProductId === PICO_PRODUCT_ID;
        });
  
        // If not already authorized, request a new port
        if (!picoPort) {
          const filters = [{ usbVendorId: PICO_VENDOR_ID, usbProductId: PICO_PRODUCT_ID }];
          picoPort = await navigator.serial.requestPort({ filters });
        }
  
        await picoPort.open({ baudRate: 115200 });
        setSelectedSerialPort(picoPort);
        readerRef.current = picoPort.readable.getReader();
        writerRef.current = picoPort.writable.getWriter();
        console.log("Serial port opened successfully with specified settings.");
      } catch (error) {
        console.error("Error accessing serial port:", error);
      }
    } else {
      console.log("Web Serial API not supported.");
    }
  };
  

  // const connectSerialPort = async () => {
  //   if ("serial" in navigator) {
  //     try {
  //       console.log("Requesting access to the serial port...");
  //       const serialPort = await navigator.serial.requestPort();
  //       await serialPort.open({ baudRate: 9600 });
  //       setSelectedSerialPort(serialPort);
  //       readerRef.current = serialPort.readable.getReader();
  //       writerRef.current = serialPort.writable.getWriter();
  //       console.log("Serial port opened successfully with specified settings.");
  //     } catch (error) {
  //       console.error("Error accessing serial port:", error);
  //     }
  //   } else {
  //     console.log("Web Serial API not supported.");
  //   }
  // };

  const startZmodemTransfer = async () => {
    setIsTransferring(true);
    console.log("Start Z-Modem Transfer");
    if (!selectedSerialPort) {
      console.error("Please connect to a serial port first.");
      setIsTransferring(false);
      return;
    }
    if (!readerRef.current && selectedSerialPort.readable) {
      readerRef.current = selectedSerialPort.readable.getReader();
    }
    if (!writerRef.current && selectedSerialPort.writable) {
      writerRef.current = selectedSerialPort.writable.getWriter();
    }
    try {
      const zSentry = new Zmodem.Sentry({
        to_terminal: (octets) => console.log(`Raw data to terminal:`, octets),
        sender: async (octets) => {
          console.log(`Raw data being sent:`, octets);
          await writerRef.current.write(new Uint8Array(octets));
        },
        on_detect: (detection) => {
          console.log("Z-Modem session detected.");
          initiateZmodemSession(detection);
        },
        on_retract: () => console.log("Z-Modem session retracted."),
      });

      await readSerialData(zSentry);
    } catch (error) {
      console.error("Error during Z-Modem transfer setup:", error);
      setIsTransferring(false);
    }
  };

  const readSerialData = async (zSentry) => {
    while (true) {
      const { value, done } = await readerRef.current.read();
      if (done) {
        console.log("Done reading from serial port. Ending Z-Modem transfer.");
        setIsTransferring(false);
        readerRef.current.releaseLock();
        writerRef.current.releaseLock();
        setIsTransferring(false);
        break;
      }
      await zSentry.consume(value);
    }
  };

  const initiateZmodemSession = (detection) => {
    const zSession = detection.confirm();
    console.log("Z-Modem session started.");
    zSession.on("session_end", () => {
      console.log("Z-Modem session ended.");
    });
    zSession.on("offer", (offer) => handleFileOffer(offer));
    zSession.start();
  };

  const handleFileOffer = async (offer) => {
    console.log("Offer Object:", offer);
    console.log("Offer received:", offer.get_details());
    try {
      let totalReceivedBytes = 0;
      offer.on("input", (payload) => {
        totalReceivedBytes += payload.length;
        // Calculate percentage and round to two decimal places
        const percentComplete = parseFloat(
          ((totalReceivedBytes / offer.get_details().size) * 100).toFixed(2)
        );
        setZmodemTransferProgress(percentComplete); // Sets the percentage with two decimal places
      });
      const payloads = await offer.accept();
      console.log("Payloads", payloads);

      const blob = new Blob(payloads, { type: "application/octet-stream" });
      const formData = new FormData();
      formData.append("file", blob, offer.get_details().name);

      console.log("Starting file upload...");
      setZmodemTransferProgress(`Uploading ${offer.get_details().name}...`);

      fetch("https://fdl-service.azurewebsites.net/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok");
          return response.text();
        })
        .then((result) => {
          console.log("Upload successful:", result);
          setZmodemTransferProgress(`Upload completed for ${offer.get_details().name}`);
          cleanupAfterTransfer();
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setZmodemTransferProgress(
            `Upload failed for ${offer.get_details().name}`
          );
        });
    } catch (err) {
      console.error("Error handling file offer:", err);
      setZmodemTransferProgress(
        `Error handling offer for ${offer.get_details().name}`
      );
    }
  };

  const cleanupAfterTransfer = async () => {
    console.log("Cleanup after transfer");
  
    // Reset transfer state
    setZmodemTransferProgress(0);
    setUploadProgress(0);
    setIsTransferring(false);
    setZmodemTransferComplete(false);
  
    // Release reader resources if applicable
    if (readerRef.current) {
      try {
        await readerRef.current.cancel(); // Stop any ongoing reads
        readerRef.current.releaseLock();
      } catch (error) {
        console.error("Error releasing reader lock:", error);
      }
      readerRef.current = null;
    }
  
    // Release writer resources if applicable
    if (writerRef.current) {
      try {
        writerRef.current.releaseLock();
      } catch (error) {
        console.error("Error releasing writer lock:", error);
      }
      writerRef.current = null;
    }
  
    // Close the serial port if it's open
    if (selectedSerialPort) {
      try {
        await selectedSerialPort.close();
        console.log("Serial port closed successfully.");
      } catch (error) {
        console.error("Error closing serial port:", error);
      }
      setSelectedSerialPort(null); // Reset the serial port state variable
    }
  
    // Close WebSocket if applicable
    if (wsRef && wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  };
  
  // const cleanupAfterTransfer = async () => {
  //   console.log("Cleanup after transfer");

  //   // Reset state variables
  //   setZmodemTransferProgress(0);
  //   setUploadProgress(0);
  //   setIsTransferring(false);
  //   setZmodemTransferComplete(false);

  //   // Release serial port resources if applicable
  //   if (readerRef.current) {
  //     try {
  //       await readerRef.current.cancel(); // Ensure you await the cancellation
  //       readerRef.current.releaseLock();
  //     } catch (error) {
  //       console.error("Error releasing reader lock:", error);
  //     }
  //     readerRef.current = null;
  //   }
  //   if (writerRef.current) {
  //     try {
  //       writerRef.current.releaseLock();
  //     } catch (error) {
  //       console.error("Error releasing writer lock:", error);
  //     }
  //     writerRef.current = null;
  //   }

  //   // Close and reset WebSocket connection if applicable
  //   if (wsRef && wsRef.current) {
  //     wsRef.current.close();
  //     wsRef.current = null;
  //   }
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const downloadFile = (filename) => {
    const url = `https://fdl-service.azurewebsites.net/download/${encodeURIComponent(
      filename
    )}`;
    window.location.href = url;
  };

  const obtainTailName = (filename) => {
    const index = filename.indexOf('_');
    if (index === -1) return '-';
    return filename.substring(0, index);
  }

  return (
    <div>
      <div className="container">
      <img className="avionica-logo" src={logo} alt="Logo" />
      <h1>avCloudConnect</h1>
        <div className="card">
          <div className="card-header text-left bg-transparent">
            <h1>FDR Downloader</h1>
          </div>
          <div className="card-body">
            {!isTransferring ? (
              <div className="card-buttons">
                <button
                  className="btn btn-warning"
                  onClick={connectSerialPort}
                  disabled={!!selectedSerialPort || isTransferring}
                >
                  Link to Web
                </button>
                <button
                  className="btn btn-warning"
                  onClick={startZmodemTransfer}
                  disabled={!selectedSerialPort || isTransferring}
                >
                  Start Data Transfer
                </button>
              </div>
            ) : (
              <>
                {!zmodemTransferComplete && (
                  <>
                    <ProgressBar
                      barHeight={20}
                      progressPercentage={zmodemTransferProgress}
                    />
                    <div>
                      Zmodem Transfer Progress: {zmodemTransferProgress}%
                    </div>
                  </>
                )}
                {zmodemTransferComplete && (
                  <>
                    <ProgressBar
                      barHeight={20}
                      progressPercentage={uploadProgress}
                    />
                    <div>Upload to Azure Progress: {uploadProgress}%</div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="file-container">
        <h3>Uploaded files</h3>
        <input
          type="text"
          placeholder="Search files..."
          onChange={handleSearchChange}
          className="search-file"
        />
        <div className="table-card">
          <table>
            <thead>
              <tr>
                <th>File</th>
                <th>Upload Date</th>
                <th>Tail Number</th>
                <th>Download Section</th>
              </tr>
            </thead>
            <tbody>
              {files
                .filter((file) =>
                  file.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((file) => (
                  <tr key={file.name}>
                    <td>{file.name}</td>
                    <td>{file.lastModified}</td>
                    <td>{obtainTailName(file.name)}</td>
                    <td><button className="btn btn-warning" onClick={() => downloadFile(file.name)}>Download File</button></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="space-files"></div>
    </div>
  );
};

export default FileTransfer;
