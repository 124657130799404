import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import styles from './scss/styles.scss';
import * as bootstrap from 'bootstrap';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Note: React.Strict mode makes useEffect hook to execute twice
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
