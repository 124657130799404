import React, { useState } from 'react';
import './App.scss';
import FileTransfer from "./components/FileTransfer/FileTransfer";
import Login from "./components/Login/Login"; // Assuming you place Login in components/Login

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {!isAuthenticated ? (
        <Login onLogin={setIsAuthenticated} />
      ) : (
        <FileTransfer />
      )}
    </div>
  );
};

export default App;
